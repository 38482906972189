/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/


.mx_RoomList_rooms {
    list-style-type: none;
    padding: 0;
    margin: 15px;
}

.mx_RoomList_rooms li {
    margin-bottom: 8px;
}

.mx_RoomList_rooms.minimized {
    margin: 0;
    margin-left: 8px;
}

.mx_RoomList_iconPlus::before {
    mask-image: url("$(res)/img/element-icons/roomlist/plus-circle.svg");
}
.mx_RoomList_iconNewRoom::before {
    mask-image: url("$(res)/img/element-icons/roomlist/hash-plus.svg");
}
.mx_RoomList_iconNewVideoRoom::before {
    mask-image: url("$(res)/img/element-icons/roomlist/hash-video.svg");
}
.mx_RoomList_iconAddExistingRoom::before {
    mask-image: url("$(res)/img/element-icons/roomlist/hash.svg");
}
.mx_RoomList_iconExplore::before {
    mask-image: url("$(res)/img/element-icons/roomlist/hash-search.svg");
}
.mx_RoomList_iconDialpad::before {
    mask-image: url("$(res)/img/element-icons/roomlist/dialpad.svg");
}
.mx_RoomList_iconStartChat::before {
    mask-image: url("@vector-im/compound-design-tokens/icons/user-add-solid.svg");
}
.mx_RoomList_iconInvite::before {
    mask-image: url("$(res)/img/element-icons/room/share.svg");
}

.mx_RoomFilter {
    position: sticky;
    top: 0;
    background: $leftPanel-bg-color;
    z-index: 10;
    justify-content: space-between;
    padding: 8px 20px 8px 8px;
    display: flex;
    gap: 8px;
}

.mx_RoomList_sortButton {
        margin-left: 15px;
        margin-bottom: 6px;
        margin-right: 10px;
        width: 32px;
        height: 32px;
        border-radius: 8px;
        position: relative;
        padding: 8px;
        background-color: $panel-actions;
        box-sizing: border-box;
        flex-shrink: 0;
        &::before {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $secondary-content;
            mask-image: url("$(res)/cx-res/img/icons/sort.svg");
        }
        &:hover {
            background-color: $accent-800;
            &::before {
                background-color: $background;
            }
        }
}
    .mx_RoomList_filterButton {
        margin-left: 15px;
        width: 32px;
        height: 32px;
        margin-bottom: 6px;
        margin-right: 10px;
        border-radius: 8px;
        position: relative;
        padding: 8px;
        background-color: $panel-actions;
        box-sizing: border-box;
        flex-shrink: 0;
        &::before {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            mask-position: center;
            mask-size: contain;
            mask-repeat: no-repeat;
            background-color: $secondary-content;
            mask-image: url("$(res)/cx-res/img/icons/filter.svg");
        }
        &:hover {
            background-color: $accent-800;
            &::before {
                background-color: $background;
            }
        }
    }
.mx_RoomFilter_filterContainer {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-wrap: wrap;
    min-width: 0;
    padding: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

.mx_RoomFilter_collapsed {
    width: 100%;
    margin-bottom: 5px;
    background-color: $leftPanel-bg-color;
    border-bottom: 1px solid $quinary-content;
    flex-direction: column;
    align-items: center;
    padding: 4px;
}

.mx_RoomFilter_filters {
    flex-wrap: wrap;
    gap: 4px;
    flex: 1;
}

.mx_RoomFilter_button {
    padding: 6px 12px;
    margin:  0px 5px 5px 0px;
    min-width: 60px;
    border-radius: 16px;
    border: none;
    background-color: $panel-actions;
    font-size: 13px;
    white-space: nowrap;
    flex-shrink: 0;
}

.mx_RoomFilter_button.active {
  background: $accent-800;
  color: white;
}

.mx_RoomFilter_button:hover {
  background: $accent-800;
  color: white;
}

.mx_RoomFilter_sortButton {
    height: 20px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: $panel-actions;
}

.mx_RoomFilter_sortIcon {
    width: 16px;
    height: 16px;
    fill: $secondary-content;
}

.mx_RoomFilter_sortButton:hover {
  color: white !important;  
  background: $accent-800;
}

.mx_RoomFilter_sortButton:hover .mx_RoomFilter_sortIcon {
  fill: white !important;
}


.mx_RoomFilter_filterButton {
    height: 20px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: $panel-actions;
}

.mx_RoomFilter_filterIcon {
    width: 16px;
    height: 16px;
    fill: $secondary-content;
}

.mx_RoomFilter_filterButton:hover {
  color: white !important;  
  background: $accent-800;
}

.mx_RoomFilter_filterButton:hover .mx_RoomFilter_filterIcon {
  fill: white !important;
}

.mx_RoomFilter_sortButton_active .mx_RoomFilter_sortIcon {
    color: #fff;
}

.mx_RoomList_spaceInfo {
    flex: 1;
    pointer-events: none;
}

.mx_IconizedContextMenu_option_selected::after {
    content: "✓";
    position: absolute;
    right: 12px;
    color: var(--accent);
}

.mx_RoomFilter_sortButton_active {
    background-color: var(--accent);
}

.mx_RoomList_spaceSection {
    margin: 4px 0;
}

.mx_RoomList_spaceHeader {
    display: flex;
    align-items: center;
    padding: 8px;
    margin: 0 -4px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.15s;
}


.mx_RoomList_spaceChildren {
    margin-left: 24px;
    padding-left: 8px;
}

.mx_RoomList_spaceChevron {
  mask-image: url("$(res)/img/compound/chevron-right-12px.svg");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
  width: 12px;
  height: 12px;
  background-color: currentColor;
  margin-right: 8px;
  transition: transform 0.2s ease;
}

.mx_RoomList_spaceChevron.expanded {
  transform: rotate(90deg);
}

@media (max-width: 480px) {
    .mx_RoomFilter {
        padding: 4px;
    }
    
    .mx_RoomFilter_button {
        padding: 4px 8px;
        min-width: 48px;
        font-size: 12px;
    }
    
    .mx_RoomList_spaceChildren {
        margin-left: 16px;
    }
}