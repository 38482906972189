/*
Copyright 2024 New Vector Ltd.
Copyright 2015, 2016 OpenMarket Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_MemberList {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 0;

    .mx_Spinner {
        flex: 1 0 auto;
    }

    .mx_SearchBox {
        margin-bottom: 5px;
    }

    h2 {
        text-transform: uppercase;
        color: $h3-color;
        font-weight: var(--cpd-font-weight-semibold);
        font-size: $font-13px;
        padding-left: 3px;
        padding-right: 12px;
        margin-top: 8px;
        margin-bottom: 4px;
    }

    .mx_AutoHideScrollbar {
        flex: 1 1 0;
        margin-top: var(--cpd-space-3x);
        display: flex;
        flex-direction: column;
        margin-right: 5px;
    }
}

.mx_MemberList_chevron {
    position: absolute;
    right: 35px;
    margin-top: -15px;
}

.mx_MemberList_border {
    overflow-y: auto;

    order: 1;
    flex: 1 1 0px;
}

.mx_MemberList_query {
    height: 30px;

    /* stricter rule to override the one in _common.pcss */
    &[type="text"] {
        font-size: $font-12px;
    }
}

.mx_MemberList_wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.mx_MemberList_invite {
    margin: 10px var(--cpd-space-2x);
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.mx_MemberList_invite[disabled] {
    margin: 10px var(--cpd-space-2x);
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

._button_zt6rp_17[data-kind="secondary"]:hover {
    background: $accent-300 !important;
}
