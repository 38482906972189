/*
Copyright 2019-2024 New Vector Ltd.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_SettingsDialog_toastContainer {
    position: absolute;
    bottom: var(--cpd-space-10x);
    width: 100%;
    display: flex;
    justify-content: center;
}

.mx_UserSettingsDialog_title {
    /* Override default dialog font style */
    font: var(--cpd-font-heading-md-regular) !important;

    .mx_UserSettingsDialog_title_strong {
        font: var(--cpd-font-heading-md-semibold);
    }
}

/* Required for CX changes */
/* ========================================================== */

.mx_UserSettingsDialog_settingsIcon::before {
    mask-image: url("$(res)/img/element-icons/settings.svg");
}

.mx_UserSettingsDialog_bellIcon::before {
    mask-image: url("$(res)/img/element-icons/notifications.svg");
}

.mx_UserSettingsDialog_preferencesIcon::before {
    mask-image: url("$(res)/img/element-icons/settings/preference.svg");
}

.mx_UserSettingsDialog_voiceIcon::before {
    mask-image: url("$(res)/img/element-icons/call/voice-call.svg");
}

.mx_UserSettingsDialog_sidebarIcon::before {
    mask-image: url("$(res)/img/element-icons/settings/sidebar.svg");
}

.mx_UserSettingsDialog_securityIcon::before {
    mask-image: url("$(res)/img/element-icons/security.svg");
}

.mx_UserMenu_iconAssist::before {
    mask-image: url("$(res)/cx-res/img/icons/assist.svg");
}

<---------- Changes from cx -------------->
.mx_UserSettingsDialog_submenuTabs {
    border-top: 1px solid var(--primary-hairline-color);
}

.mx_UserSettingsDialog_submenuTabs .mx_TabbedView_tabLabels {
       margin-top: 210px;
        width: 220px;
        z-index:10;
        max-width: 220px;
}

.mx_TabbedView_tabLabel:hover,
    .mx_TabbedView_tabLabel_active {
        color: $tab-label-active-fg-color;
        width: 200px;

        .mx_TabbedView_maskedIcon::before {
            background-color: var(--cpd-color-icon-primary);
        }
    }

.mx_UserSettingsDialog_submenuTabs .mx_TabbedView {
    display: flex;
    flex-direction: column;
}

.mx_UserSettingsDialog_submenuTabs .mx_TabbedView_tabPanel {
    box-sizing: border-box;
}

.mx_UserSettingsDialog_warning {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    max-width: 620px;
    color: #856404;
    padding: 10px;
    margin-right:25px;
    margin-bottom: 15px;
    border-radius: 4px;
}
