/*
Copyright 2024 New Vector Ltd.
Copyright 2023 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_MessagePanel_myReadMarker {
        height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    transition: margin 0.3s ease;

    &.visible {
        margin-bottom: 50px  ;
    }

    .custom-hr {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $header-panel-bg-color;
        border: 1px solid $header-panel-bg-color;
        border-radius: 8px;
        margin: 10px 0;
        position: relative;
        top: -1px;
        z-index: 1;
        color: $accent;
        font-weight: bold;
        will-change: width;
        transform-origin: center;
    }

    .custom-hr::before {
        content: attr(data-i18n-content);
    }
}

.mx_MessagePanel_imageGroup {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    max-width: 500px;
    margin-bottom: 10px;
    margin-left: auto; 
    margin-right: 0; 
    margin-top: 20px;
}

.mx_MessagePanel_imageGroup .mx_EventTile[data-layout="group"] .mx_EventTile_line {
    margin-right: 0 !important;
}

.mx_EventTile_content img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
}

.mx_MessagePanel_imageGroup .mx_MImageBody img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
}