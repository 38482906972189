#hoverContainer {
    position: relative;
    align-content: center;
    right: 0;
    z-index: 1000;
    width: 60px;
    height: 60px;
}

#chatbotButton {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: $panel-actions;
    border-radius: 8px;
    cursor: pointer;
    background-image: var(--bot-img);
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.3s ease;
    margin-left: auto;
    margin-right: 10px;
}

#chatbotButton:hover {
    background-color: $accent-800;
    transform: scale(1.1);
}

.cxChatbotFloating-element {
    right: 0;
    margin-right: 0px;
    z-index: 1000;
}

.cxChatbotButton-minimized {
    width: 40px;
    height: 40px;
    margin: 10px auto;
}
.cxChatbotButton-minimized #hoverContainer,
.cxChatbotButton-minimized #chatbotButton {
    width: 100%;
    height: 100%;
}

.cxDefaultUserReactionOnBotMessage {
    background-color: var(--cpd-color-bg-subtle-secondary);
    border-radius: 15px;
    padding: 15px;
    margin: 10px 0;
    box-shadow: 0 2px 5px var(--cpd-color-alpha-gray-300);
}

.cxDefaultUserReactionOnBotMessageChild > * {
    margin: 10px 0;
}

.cxHoverButton {
    background-color: $accent-400;
    color: var(--cpd-color-text-primary);
    border-radius: 20px;
    border-color: $accent-400;
    padding: 8px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
}

.cxHoverButton:hover {
    background-color: $accent-600;
}

.cxSubmitButton {
    background-color: rgb(18, 129, 146);
    color: var(--cpd-color-text-on-solid-primary);
    border: 0px;
    border-radius: 15px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    transition: all 0.2s ease;
    outline: none;
}

.cxSubmitButton:hover {
    background-color: rgb(14, 103, 117);
    border-color: var(--cpd-color-border-interactive-hovered);
}

.cxSubmitButton:active {
    background-color: var(--cpd-color-bg-action-primary-pressed);
    border-color: var(--cpd-color-border-interactive-pressed);
}

.cxCollapsible-button {
    background-color: rgb(18, 129, 146);
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}

.cxCollapsible-button:hover {
    background-color: rgb(14, 103, 117);
}

.cxParentChildNavigator {
    padding: 15px;
    margin-top: 10px;
}

.cxParentChildNavigator-item {
    background-color: var(--cpd-color-bg-subtle-secondary);
    border: 1px solid var(--cpd-color-border-interactive-primary);
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cxParentChildNavigator-item:hover {
    background-color: var(--cpd-color-bg-subtle-primary);
}

.cxAskAgainButton {
    background-color: rgb(18, 129, 146);
    color: var(--cpd-color-text-on-solid-primary);
    border: 1px solid rgb(18, 129, 146);
    border-radius: 15px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    transition: all 0.2s ease;
    outline: none;
    margin: 10px;
}

.cxAskAgainButton:hover {
    background-color: rgb(14, 103, 117);
    border-color: rgb(14, 103, 117);
}