/*
Copyright 2024 New Vector Ltd.
Copyright 2017 Vector Creations Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_AccessibleButton {
    cursor: pointer;

    &.mx_AccessibleButton_disabled {
        cursor: not-allowed;
        opacity: 0.6;
        background-color: #d3d3d3 !important;
        color: #808080 !important;
        border-color: #a9a9a9 !important;
    }

    &.mx_AccessibleButton_hasKind {
        padding: 8px 16px;
        text-align: center;
        border-radius: 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font: var(--cpd-font-body-md-semibold);
        border: none;
        transition: background-color 0.2s ease, color 0.2s ease;

        &.mx_AccessibleButton_kind_primary_sm,
        &.mx_AccessibleButton_kind_danger_sm,
        &.mx_AccessibleButton_kind_link_sm {
            padding: 6px 12px;
            font-size: 14px;
        }

        &.mx_AccessibleButton_kind_primary_sm {
            color: var(--cpd-color-text-on-solid-primary);
            background-color: $accent-800;
        }

        &.mx_AccessibleButton_kind_danger_sm {
            color: var(--cpd-color-text-on-solid-primary);
            background-color: #A50021;
        }

        &.mx_AccessibleButton_kind_link_sm {
            color: $accent-800;
            text-decoration: none;
            font-weight: var(--cpd-font-weight-semibold);

            &:hover {
                text-decoration: underline;
            }
        }

        &.mx_AccessibleButton_kind_confirm_sm {
            background-color: $accent-800;

            &::before {
                mask-image: url("@vector-im/compound-design-tokens/icons/check.svg");
            }
        }

        &.mx_AccessibleButton_kind_cancel_sm {
            background-color: #A50021;

            &::before {
                mask-image: url("@vector-im/compound-design-tokens/icons/close.svg");
            }
        }

        &.mx_AccessibleButton_kind_icon,
        &.mx_AccessibleButton_kind_icon_primary,
        &.mx_AccessibleButton_kind_icon_primary_outline {
            padding: 0;
            height: 32px;
            width: 32px;
            border-radius: 4px;
        }
    }

    &.mx_AccessibleButton_kind_icon_primary,
    &.mx_AccessibleButton_kind_icon_primary_outline,
    &.mx_AccessibleButton_kind_primary,
    &.mx_AccessibleButton_kind_primary_outline,
    &.mx_AccessibleButton_kind_secondary {
        font-weight: var(--cpd-font-weight-semibold);
    }

    &.mx_AccessibleButton_kind_icon_primary,
    &.mx_AccessibleButton_kind_primary {
        border: 1px solid $accent-800;
        color: var(--cpd-color-text-on-solid-primary);
        background-color: $accent-800;

        &:hover {
            background-color: $accent-1000;
            border-color: $accent-1000;
        }
    }

    &.mx_AccessibleButton_kind_icon_primary_outline,
    &.mx_AccessibleButton_kind_primary_outline {
        border: 1px solid $accent-800;
        color: $accent-800;
        background-color: transparent;

        &:hover {
            background-color: $accent-300;
        }
    }

    &.mx_AccessibleButton_kind_secondary {
        color: $accent-800;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    &.mx_AccessibleButton_kind_secondary_content {
        color: $accent-800;
    }

    &.mx_AccessibleButton_kind_danger {
        color: var(--cpd-color-text-on-solid-primary);
        background-color: #A50021;
        border: 1px solid #A50021;

        &:hover {
            background-color: #8B001C;
            border-color: #8B001C;
        }

        &.mx_AccessibleButton_disabled {
            color: var(--cpd-color-text-on-solid-primary);
            background-color: #893F45;
            border-color: #893F45;
        }
    }

    &.mx_AccessibleButton_kind_danger_outline {
        color: #A50021;
        background-color: transparent;
        border: 1px solid #A50021;

        &:hover {
            background-color: #FFE5E5;
        }

        &.mx_AccessibleButton_disabled {
            color: #893F45;
            border-color: #893F45;
        }
    }

    &.mx_AccessibleButton_kind_danger_sm {
        &.mx_AccessibleButton_disabled {
            color: var(--cpd-color-text-disabled);
            background-color: #893F45;
        }
    }

    &.mx_AccessibleButton_kind_link,
    &.mx_AccessibleButton_kind_link_inline,
    &.mx_AccessibleButton_kind_danger_inline,
    &.mx_AccessibleButton_kind_content_inline {
        font-size: inherit;
        font-weight: var(--cpd-font-weight-semibold);
        line-height: inherit;
        padding: 0;
        text-decoration: none;
        background-color: transparent;

        &:hover {
            text-decoration: underline;
        }
    }

    &.mx_AccessibleButton_kind_link,
    &.mx_AccessibleButton_kind_link_inline {
        color: $accent-800;
    }

    &.mx_AccessibleButton_kind_danger_inline {
        color: #A50021;
    }

    &.mx_AccessibleButton_kind_content_inline {
        color: $accent-800;
    }

    &.mx_AccessibleButton_kind_link_inline,
    &.mx_AccessibleButton_kind_danger_inline,
    &.mx_AccessibleButton_kind_content_inline {
        display: inline;
    }

    &.mx_AccessibleButton_kind_confirm_sm,
    &.mx_AccessibleButton_kind_cancel_sm {
        padding: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: "";
            width: 16px;
            height: 16px;
            background-color: #ffffff;
            mask-repeat: no-repeat;
            mask-position: center;
            mask-size: contain;
        }
    }
}
