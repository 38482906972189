/*
Copyright 2024 New Vector Ltd.
Copyright 2019, 2020 The Matrix.org Foundation C.I.C.
Copyright 2018 New Vector Ltd

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_CreateSecretStorageDialog {
    /* Why you ask? Because CompleteSecurityBody is 600px so this is the width */
    /* we end up when in there, but when in our own dialog we set our own width */
    /* so need to fix it to something sensible as otherwise we'd end up either */
    /* really wide or really narrow depending on the phase. I bet you wish you */
    /* never asked. */
    width: 560px;

    &.mx_SuccessDialog {
        padding: 28px; /* 80px from design - 24px wrapper padding */

        .mx_Dialog_title {
            margin-bottom: $spacing-16;
        }
    }

    .mx_SettingsFlag {
        display: flex;
    }

    .mx_SettingsFlag_label {
        flex: 1 1 0;
        min-width: 0;
        font-weight: var(--cpd-font-weight-semibold);
    }

    .mx_ToggleSwitch {
        flex: 0 0 auto;
        margin-left: 30px;
    }

    details .mx_AccessibleButton {
        margin: 1em 0; /* emulate paragraph spacing because we can't put this button in a paragraph due to HTML rules */
    }
}

.mx_CreateSecretStorageDialog .mx_Dialog_title {
    /* TODO: Consider setting this for all dialog titles. */
    margin-bottom: 0.2em;
}

.mx_CreateSecretStorageDialog_titleWithIcon::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    position: relative;
    top: 5px;
    background-color: $primary-content;
}

.mx_CreateSecretStorageDialog_secureBackupTitle::before {
    mask-image: url("$(res)/img/feather-customised/secure-backup.svg");
}

.mx_CreateSecretStorageDialog_securePhraseTitle::before {
    mask-image: url("$(res)/img/feather-customised/secure-phrase.svg");
}

.mx_CreateSecretStorageDialog_centeredTitle,
.mx_CreateSecretStorageDialog_centeredBody {
    text-align: center;
}

.mx_CreateSecretStorageDialog_primaryContainer {
    /* FIXME: plinth colour in new theme(s). background-color: $accent; */
    padding-top: 20px;

    &.mx_CreateSecretStorageDialog_recoveryKeyPrimarycontainer {
        display: flex;
        justify-content: center;
    }
}

.mx_CreateSecretStorageDialog_primaryContainer::after {
    content: "";
    clear: both;
    display: block;
}

.mx_CreateSecretStorageDialog_primaryContainer .mx_StyledRadioButton {
    margin-bottom: 16px;
    padding: 11px;
}

.mx_CreateSecretStorageDialog_optionTitle {
    color: $dialog-title-fg-color;
    font-weight: var(--cpd-font-weight-semibold);
    font-size: $font-18px;
    padding-bottom: 10px;
}

.mx_CreateSecretStorageDialog_optionIcon {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    position: relative;
    top: 5px;
    background-color: $primary-content;
}

.mx_CreateSecretStorageDialog_optionIcon_securePhrase {
    mask-image: url("$(res)/img/feather-customised/secure-phrase.svg");
}

.mx_CreateSecretStorageDialog_optionIcon_secureBackup {
    mask-image: url("$(res)/img/feather-customised/secure-backup.svg");
}

.mx_CreateSecretStorageDialog_passPhraseContainer {
    display: flex;
    align-items: flex-start;
}

.mx_Field.mx_CreateSecretStorageDialog_passPhraseField {
    margin-top: 0px;
}

.mx_CreateSecretStorageDialog_passPhraseMatch {
    width: 200px;
    margin-left: 20px;
}

.mx_CreateSecretStorageDialog_recoveryKeyContainer {
    display: inline-block;
}

.mx_CreateSecretStorageDialog_recoveryKey {
    font-weight: bold;
    text-align: center;
    padding: 20px;
    color: $info-plinth-fg-color;
    background-color: $info-plinth-bg-color;
    border-radius: 6px;
    word-spacing: 1em;
    margin-bottom: 20px;

    code {
        display: inline-block;
        width: 30rem;
    }
}

.mx_CreateSecretStorageDialog_recoveryKeyButtons {
    align-items: center;
    display: flex;
    gap: 16px;
}

.mx_CreateSecretStorageDialog_recoveryKeyButtons_copyBtn {
    flex-direction: column;
}

.mx_CreateSecretStorageDialog_recoveryKeyButtons .mx_AccessibleButton {
    flex-grow: 1;
    white-space: nowrap;
}

.mx_CreateSecretStorageDialog_continueSpinner {
    margin-top: 33px;
    text-align: right;
}

.mx_CreateSecretStorageDialog_continueSpinner img {
    width: 20px;
    height: 20px;
}
