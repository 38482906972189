/*
Copyright 2024 New Vector Ltd.
Copyright 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_BaseCard {
    --BaseCard_EventTile_line-padding-block: 2px;
    --BaseCard_EventTile-spacing-inline: 36px;
    --BaseCard_header-button-size: 28px;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: var(--cpd-font-size-body-md);

    .mx_BaseCard_header {
        height: 64px;
        
        box-sizing: border-box;
        /* changing the color from $separator to transparent as it is
           the best visual output during the transition period. This will be
           reintroduced at a later stage. */
        border-bottom: 1px solid transparent;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
        margin-left: 10px;
        gap: var(--cpd-space-3x);
        flex-shrink: 0;
        border-block-end: var(--cpd-border-width-1) solid $separator;

        .mx_BaseCard_header_spacer {
            flex: 1;
        }

        > h2 {
            margin: 0 44px;
            font: var(--cpd-font-body-sm-semibold);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .mx_BaseCard_header_title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 24px;
            flex: 1;

            .mx_BaseCard_header_title_heading {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font: var(--cpd-font-body-md-medium);
                color: var(--cpd-color-text-secondary);
            }

            .mx_BaseCard_header_title_button--option {
                position: relative;
                width: var(--BaseCard_header-button-size);
                height: var(--BaseCard_header-button-size);

                &::after {
                    content: "";
                    position: absolute;
                    inset-block-start: 0;
                    inset-inline-start: 0;
                    height: 100%;
                    width: 100%;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    mask-image: url("@vector-im/compound-design-tokens/icons/overflow-horizontal.svg");
                    background-color: $secondary-content;
                }

                &:hover::after {
                    background-color: $accent-300;
                }
            }
        }
    }

    .mx_AutoHideScrollbar {
        min-height: 0;
        width: 100%;
        height: 100%;
        scrollbar-gutter: stable;
    }

    .mx_BaseCard_footer {
        padding-top: $spacing-4;
        text-align: center;
        display: flex;
        justify-content: space-around;

        .mx_AccessibleButton_kind_secondary {
            color: $secondary-content;
            background-color: rgba(141, 151, 165, 0.2);
            font: var(--cpd-font-body-md-semibold);
        }

        .mx_AccessibleButton_disabled {
            cursor: not-allowed;
        }
    }
}

.mx_ContextualMenu_wrapper.mx_BaseCard_header_title {
    .mx_ContextualMenu {
        position: initial;

        span:first-of-type {
            font-weight: var(--cpd-font-weight-semibold);
            font-size: inherit;
            color: $primary-content;
        }

        font: var(--cpd-font-body-sm-regular);
        color: $primary-content;
        padding-top: 10px;
        padding-bottom: 10px;

        border: var(--cpd-border-width-1) solid var(--cpd-color-border-interactive-secondary);
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
    }

    .mx_ContextualMenu_chevron_top {
        left: auto;
        right: 22px;
        border-bottom-color: var(--cpd-color-border-interactive-secondary);

        &::after {
            content: "";
            border: inherit;
            border-bottom-color: var(--cpd-color-bg-canvas-default);
            position: absolute;
            top: 1px;
            left: -8px;
        }
    }
}
