/*
Copyright 2024 New Vector Ltd.
Copyright 2019, 2020 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_InviteDialog_flexWrapper .mx_Dialog {
    display: flex;
    flex-direction: column;
}

.mx_InviteDialog_transferWrapper .mx_Dialog {
    padding-bottom: $spacing-16;
}

.mx_InviteDialog_addressBar {
    display: flex;
    flex-direction: row;
    /* Right margin for the design. We could apply this to the whole dialog, but then the scrollbar */
    /* for the user section gets weird. */
    margin: $spacing-8 45px 0 0;

    .mx_InviteDialog_editor {
        flex: 1;
        width: 100%; /* Needed to make the Field inside grow */
        background-color: $header-panel-bg-color;
        border-radius: 4px;
        min-height: 25px;
        padding-inline-start: $spacing-8;
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;

        .mx_InviteDialog_userTile {
            margin: 6px 6px 0 0;
            display: inline-block;
            min-width: max-content; /* prevent manipulation by flexbox */
        }

        /* overrides bunch of our default text input styles */
        > input[type="text"] {
            margin: 6px 0 !important;
            height: 24px;
            font: var(--cpd-font-body-md-regular);
            line-height: $font-24px;
            padding-inline-start: $spacing-12;
            border: 0 !important;
            outline: 0 !important;
            resize: none;
            box-sizing: border-box;
            min-width: 40%;
            flex: 1 !important;
            color: $primary-content !important;
        }
    }

    .mx_InviteDialog_goButton {
        min-width: 48px;
        margin-inline-start: 10px;
        height: 25px;
        line-height: $font-25px;
    }

    .mx_InviteDialog_buttonAndSpinner {
        .mx_Spinner {
            /* Width and height are required to trick the layout engine. */
            width: 20px;
            height: 20px;
            margin-inline-start: 5px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}

.mx_InviteDialog_section {
    padding-bottom: $spacing-4;

    h3 {
        font-size: $font-12px;
        color: $muted-fg-color;
        font-weight: bold;
        text-transform: uppercase;
    }

    > p {
        margin: 0;
    }

    > span {
        color: $primary-content;
    }

    .mx_InviteDialog_section_showMore {
        margin: 7px 18px;
        display: block;
    }
}

.mx_InviteDialog_section_hidden_suggestions_disclaimer {
    padding: $spacing-8 0 $spacing-16 0;
    font: var(--cpd-font-body-md-regular);

    > span {
        color: $primary-content;
        font-weight: var(--cpd-font-weight-semibold);
    }

    > p {
        margin: 0;
    }
}

.mx_InviteDialog_footer {
    border-top: 1px solid $input-border-color;

    > h3 {
        margin: $spacing-12 0;
        font-size: $font-12px;
        color: $muted-fg-color;
        font-weight: bold;
        text-transform: uppercase;
    }

    .mx_CopyableText.mx_CopyableText_border {
        width: unset; /* full width */
        margin-bottom: 0;

        > a {
            text-decoration: none;
            flex-shrink: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

/* Many of these styles are stolen from mx_UserPill, but adjusted for the invite dialog. */
.mx_InviteDialog_userTile {
    margin-inline-end: $spacing-8;

    .mx_InviteDialog_userTile_pill {
        background-color: var(--cpd-color-bg-canvas-default);
        border: 1px solid var(--cpd-color-gray-400);
        border-radius: 99px;
        display: inline-block;
        height: 24px;
        line-height: $font-24px;
        padding-inline: $spacing-8;
        vertical-align: middle;
        color: var(--cpd-color-gray-1100);

        .mx_SearchResultAvatar {
            border-radius: 20px;
            position: relative;
            left: -5px;
            top: 2px;
        }

        img.mx_SearchResultAvatar {
            vertical-align: top;
        }

        .mx_InviteDialog_userTile_name {
            vertical-align: top;
        }

        .mx_SearchResultAvatar_threepidAvatar {
            background-color: #ffffff; /* this is fine without a var because it's for both themes */
        }
    }

    .mx_InviteDialog_userTile_remove {
        display: inline-block;
        vertical-align: middle;

        svg {
            vertical-align: middle;
        }
    }
}

.mx_InviteDialog_other {
    /* Prevent the dialog from jumping around randomly when elements change. */
    display: flex;
    flex-direction: column;
    height: 600px;
    overflow: hidden;

    h2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        overflow: hidden;
    }

    .mx_InviteDialog_addressBar {
        margin-inline-end: 0;
    }

    .mx_InviteDialog_userSections {
        flex-grow: 1;
        padding-inline-end: 0;

        .mx_InviteDialog_section {
            padding-bottom: 0;
            margin-top: $spacing-12;
        }
    }
}

.mx_InviteDialog_content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

.mx_InviteDialog_transfer {
    width: auto;

    .mx_InviteDialog_content {
        width: 496px;
        height: 430px;
        overflow: visible;

        .mx_TabbedView {
            display: flex;
            flex-direction: column;
            flex-shrink: 1;
            flex-grow: 1;
            min-height: 0;

            .mx_TabbedView_tabPanel {
                flex-direction: column;

                .mx_TabbedView_tabPanelContent {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .mx_InviteDialog_addressBar {
        margin-top: $spacing-8;
    }

    input[type="checkbox"] {
        margin-inline-end: $spacing-8;
    }
}

.mx_InviteDialog_userSections {
    margin-top: $spacing-4;
    overflow-y: auto;
    padding: 0 45px $spacing-4 0;
}

.mx_InviteDialog_helpText {
    margin: 0;
}

.mx_InviteDialog_dialPad {
    width: 224px;
    margin-top: $spacing-16;
    margin-inline: auto;

    .mx_InviteDialog_dialPadField {
        border-top: 0;
        border-inline: 0;
        border-radius: 0;
        margin-top: 0;
        border-color: $quaternary-content;

        &:focus-within {
            border-color: $accent;
        }

        input {
            font-size: 18px;
            font-weight: var(--cpd-font-weight-semibold);
            padding-top: 0;
        }

        .mx_Field_postfix {
            /* Remove border separator between postfix and field content */
            border-left: none;
        }
    }

    .mx_DialPad {
        row-gap: $spacing-16;
        column-gap: 48px;
        margin-inline: auto;
    }
}

.mx_InviteDialog_transferConsultConnect {
    padding-top: $spacing-16;
    /* This wants a drop shadow the full width of the dialog, so use negative margin to make it full width,
     * then compensate with padding
     */
    padding-inline: $spacing-24;
    margin-inline: calc(-1 * $spacing-24);
    border-top: 1px solid $quinary-content;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.mx_InviteDialog_transferConsultConnect_pushRight {
    margin-inline-start: auto;
}

.mx_InviteDialog_userDirectoryIcon::before {
    mask-image: url("$(res)/img/voip/tab-userdirectory.svg");
}

.mx_InviteDialog_dialPadIcon::before {
    mask-image: url("$(res)/img/voip/tab-dialpad.svg");
}

.mx_InviteDialog_tile {
    cursor: pointer;
    display: grid;
    gap: $spacing-8 $spacing-12;
    align-items: center;

    &.mx_InviteDialog_tile--room {
        /* mx_InviteDialog_tile_avatarStack, mx_InviteDialog_tile_nameStack, time */
        grid-template-columns: min-content auto auto;
        padding: $spacing-4 $spacing-8;

        &:hover {
            background-color: $accent-300;
            border-radius: 4px;
        }

        .mx_InviteDialog_tile--room_selected {
            border-radius: 36px;
            background-color: var(--cpd-color-bg-success-subtle);

            &::before {
                content: "";
                width: 24px;
                height: 24px;
                grid-column: 1;
                grid-row: 1;
                mask-image: url("@vector-im/compound-design-tokens/icons/check.svg");
                mask-size: 100%;
                mask-repeat: no-repeat;
                position: absolute;
                top: 6px; /* 50% */
                left: 6px; /* 50% */
                background-color: $primary-content;
            }
        }

        .mx_InviteDialog_tile--room_time {
            margin-inline-start: auto;
            width: max-content;
            font-size: $font-12px;
            color: $muted-fg-color;
        }

        .mx_InviteDialog_tile--room_highlight {
            font-weight: 900;
        }
    }

    &.mx_InviteDialog_tile--inviterError {
        grid-template-columns: max-content auto; /* max-content = avatar width */
        margin-bottom: $spacing-24;

        &:last-child {
            margin-bottom: 0;
        }

        .mx_InviteDialog_tile--inviterError_errorText {
            grid-row-start: 2;
            grid-column-start: 2;

            font-size: $font-15px;
            color: $alert;
        }
    }

    * {
        vertical-align: middle;
    }

    .mx_InviteDialog_tile_avatarStack,
    .mx_InviteDialog_tile--room_selected {
        width: 36px;
        height: 36px;
        display: inline-block;
        position: relative;
    }

    .mx_InviteDialog_tile_avatarStack {
        grid-row-start: 1;
        grid-column-start: 1;

        & > * {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    .mx_InviteDialog_tile_nameStack {
        grid-row-start: 1;
        grid-column-start: 2;

        display: flex;
        flex-flow: column;
        align-self: center;
        align-items: baseline;
        gap: 2px 0;
        overflow: hidden;

        .mx_InviteDialog_tile_nameStack_name,
        .mx_InviteDialog_tile_nameStack_userId {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
        }

        .mx_InviteDialog_tile_nameStack_name {
            font-size: $font-15px;
            font-weight: var(--cpd-font-weight-semibold);
            color: $primary-content;
        }

        .mx_InviteDialog_tile_nameStack_userId {
            font-size: $font-12px;
            color: $muted-fg-color;
        }
    }
}

.mx_InviteDialog_multiInviterError {
    > h4 {
        font-size: $font-15px;
        line-height: $font-24px;
        color: $secondary-content;
        font-weight: normal;
    }
}

.mx_InviteDialog_identityServer {
    margin-top: 1em;
}

.mx_InviteDialog_oneThreepid {
    font-size: $font-12px;
    margin: $spacing-8 0;
}
