/*
Copyright 2024 New Vector Ltd.
Copyright 2022 The Matrix.org Foundation C.I.C.

SPDX-License-Identifier: AGPL-3.0-only OR GPL-3.0-only
Please see LICENSE files in the repository root for full details.
*/

.mx_SettingsSubsection {
    width: 100%;
    box-sizing: border-box;

    &.mx_SettingsSubsection_newUi {
        display: flex;
        flex-direction: column;
        gap: var(--cpd-space-6x);
    }

    *[role="separator"] {
        /**
         * The gap between subsections is 32px and inside the subsection is 24px.
         * The separator separates the subsections, so it should have the same gap as the subsections.
         * We add 12px and the separator spacing to the top margin to make the separator visually centered between the subsections.
         */
        margin-top: calc(var(--cpd-space-3x) + var(--cpd-separator-spacing));
    }
}

.mx_SettingsSubsection_description {
    margin-top: $spacing-8;
}

.mx_SettingsSubsection_text {
    width: 100%;
    box-sizing: inherit;
    color: $secondary-content;
}

.mx_SettingsSubsection_content {
    width: 100%;
    display: grid;
    margin-bottom: 30px;
    grid-gap: $spacing-8;
    /* setting minwidth 0 makes columns definitely sized fixing horizontal overflow */
    grid-template-columns: minmax(0, 1fr);
    justify-items: flex-start;
    margin-top: $spacing-24;

    summary {
        color: $accent;
    }
    details[open] {
        summary {
            margin-bottom: $spacing-8;
        }
    }

    &.mx_SettingsSubsection_contentStretch {
        justify-items: stretch;
        margin-top: 10px;
    }

    &.mx_SettingsSubsection_noHeading {
        margin-top: 0;
    }
    &.mx_SettingsSubsection_content_newUi {
        gap: var(--cpd-space-6x);
        margin-top: 0;
    }

    .mx_SettingsSubsection_dropdown {
        min-width: 360px;
    }

    .mx_QuickLanguageSwitcher_heading{
        font-weight: var(--cpd-font-weight-semibold);
        font-size: $font-12px;
        line-height: $font-15px;
        color: $secondary-content;
        text-transform: uppercase;
        display: inline-block;
        margin: 0;
    }
}

.mx_GeneralUserSettingsTab_section_hint{
    margin-top: 10px;
    margin-bottom: 10px;
    color: $secondary-content;
}

.mx_GeneralUserSettingsTab_section_languageHeading{
    margin-top: 10px;
    color: $secondary-content;
    margin-bottom: 5px;
}

.mx_GeneralUserSettingsTab_section_fontSizeHeading{
    margin-top: 10px;
    color: $secondary-content;
}

.mx_GeneralUserSettingsTab_section_blockUserHeading{
    margin-top: 10px;
    margin-bottom: 5px;
    color: $secondary-content;
}

.mx_GeneralUserSettingsTab_section_blockUserDropdown{
    max-width: 450px;
    margin-top: 10px;
}